export const about = [
  { path: "#", text: "Careers" },
  { path: "#", text: "Our Stores" },
  { path: "#", text: "Our Cares" },
  { path: "#", text: "Term Conditions" },
  { path: "#", text: "Privacy Policy" },
];
export const customerCare = [
  { path: "#", text: "Hepl Center" },
  { path: "#", text: "How to Buy" },
  { path: "#", text: "Track You Order" },
  { path: "#", text: "Corporate & Bulk Purchasing" },
  { path: "#", text: "Returns & Refunds" },
];
