import fourtyFivePercent from "../images/$45.webp";
import thirtyPercent from "../images/banner-1.webp";

export const imagesSec = [
  {
    img: thirtyPercent,
  },
  {
    img: fourtyFivePercent,
  },
];
