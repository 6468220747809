import newArrival1 from "../images/newArival1.webp";
import newArrival2 from "../images/newArival2.webp";
import newArrival3 from "../images/newArival3.webp";
import newArrival4 from "../images/newArival4.webp";
import newArrival5 from "../images/newArival5.webp";
import newArrival6 from "../images/newArival6.webp";

export const newArivalOne = [
  {
    img: newArrival1,
    text: "Sunglass",
    price: "$150.00",
  },
  {
    img: newArrival2,
    text: "Mekeup",
    price: "$250.00",
  },
];
export const newArivalTwo = [
  {
    img: newArrival3,
    text: "Smart Watch",
    price: "$350.00",
  },
  {
    img: newArrival4,
    text: "Lipstic",
    price: "$15.00",
  },
];
export const newArivalThree = [
  {
    img: newArrival4,
    text: "Lipstic",
    price: "$15.00",
  },
  {
    img: newArrival5,
    text: "Green plant",
    price: "$55.00",
  },
  {
    img: newArrival6,
    text: "Bonsai tree",
    price: "$535.00",
  },
];
