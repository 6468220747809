import category1 from "../images/category-1.webp";
import category2 from "../images/category-2.webp";
import category3 from "../images/category-3.webp";

export const topCategoryData = [
  { img: category1, text: "Headphone" },
  { img: category2, text: "Watch" },
  { img: category3, text: "Sunglass" },
  { img: category1, text: "Headphone" },
  { img: category2, text: "Watch" },
];
